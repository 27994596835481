import React, { useState, useEffect } from "react";
import DatePicker from 'react-datepicker';
import useStripeConnect from "../components/UseStripeConnect";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faStripe } from "@fortawesome/free-brands-svg-icons";
import { stripeAccount, getUserInfo, updateBasicInfo } from "../api/api";
import logoYG from '../assets/icons/Logo Monogramme YG 10.png';
import styles from '../StripeHome.module.css';
import 'react-datepicker/dist/react-datepicker.css';

export default function StripeHome() {
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [onboardingExited, setOnboardingExited] = useState(false);
  const [error, setError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [isSavedFailed, setIsSavedFailed] = useState(false);
  const [birthDate, setBirthDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 18)));
  const [isModified, setIsModified] = useState(false);
  const [userInfo, setUserInfo] = useState({
    email: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    address: {}
  });
  const [connectedAccountId, setConnectedAccountId] = useState();
  const stripeConnectInstance = useStripeConnect(connectedAccountId);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserInfo();
        const user = userData.data;
        setUserInfo(user);

      } catch (error) {
        console.error('Erreur lors de la récupération des données user:', error);
        // Gérer l'erreur ici
      }
    };

    fetchUserData();
  }, [isSaved]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (['street', 'city', 'postalCode', 'country', 'additionalInfo'].includes(name)) {
      // Update only the address subfields when they belong to the address object
      setUserInfo(prevState => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value
        }
      }));
    } else {
      // Update other user fields, such as birthDate, outside of the address object
      setUserInfo(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    setIsModified(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    if (birthDate > eighteenYearsAgo) {
      alert("Vous devez être majeur pour continuer.");
      return;
    }

    const updatedUserInfo = {
      birthDate: birthDate.toISOString(), // Make sure to send the birthDate in ISO format or as required by the backend
      address: userInfo.address // Send only the address object
    };

    try {
      const response = await updateBasicInfo(updatedUserInfo);
      if (response.status === 200) {
        setSuccessMessage('Sauvegardé avec succès.');
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
        setIsSaved(true);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des informations:", error);
      setError(`Erreur lors de la sauvegarde de la date de naissance.`);
      setTimeout(() => {
        setError(null);
      }, 3000);
      setIsSavedFailed(true);
    }
  };

  return (
    <div className={styles.stripePage}>
      <div className={styles.container}>
        <div>
          <img
            src={logoYG}
            alt="Logo Your guests"
            className={styles.logo}
          />
          {/* <h2>Your Guests</h2> */}
        </div>
        <div className={styles.content}>
          {!connectedAccountId && <h2 className={styles.titreh2}>Configurez votre portefeuille en ligne avec Stripe !</h2>}
          {connectedAccountId && !stripeConnectInstance && <h2 className={styles.titreh2}>Ajoutez vos informations pour pouvoir recevoir de l'argent de Your Guests</h2>}
          {!connectedAccountId && <p className={styles.infop}>Pour recevoir l'argent des réservations de vos invités, vous devez configurer un portefeuille en ligne avec <a className={styles.hrefColor} href="https://docs.stripe.com/security?locale=fr-FR">Stripe</a>. Your Guests s'est associé à Stripe pour vous garantir une sécurité optimale.</p>}
          {!connectedAccountId &&
            <div className={styles.birthContainer}>
              <div className={styles.birthLabel}>
                <p>Avant de continuer, confirmez que vous êtes majeur.</p>
                <p>Date de naissance :</p>
              </div>
              <DatePicker
                locale="fr"
                selected={birthDate}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  setBirthDate(date);
                  setIsModified(true);
                }}
                showYearDropdown
                showMonthDropdown
                yearDropdownItemNumber={30}
                scrollableYearDropdown
                minDate={new Date("1960-01-01")}
                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                className={styles.dateSelectorInput}
              />
              <div className={styles.row}>
                <div className={styles.inputGroup}>
                  <p className={styles.profileLabel}>Numéro et rue :</p>
                  <input
                    type="text"
                    name="street"
                    value={userInfo.address?.street || ''}
                    onChange={handleInputChange}
                    className={styles.profileInput}
                  />
                </div>
                <div className={styles.inputGroup}>
                  <p className={styles.profileLabel}>Ville :</p>
                  <input
                    type="text"
                    name="city"
                    value={userInfo.address?.city || ''}
                    onChange={handleInputChange}
                    className={styles.profileInput}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.inputGroup}>
                  <p className={styles.profileLabel}>Informations complémentaires :</p>
                  <input
                    type="text"
                    name="additionalInfo"
                    value={userInfo.address?.additionalInfo || ''}
                    onChange={handleInputChange}
                    className={styles.profileInput}
                  />
                </div>
                <div className={styles.inputGroup}>
                  <p className={styles.profileLabel}>Code postal :</p>
                  <input
                    type="text"
                    name="postalCode"
                    value={userInfo.address?.postalCode || ''}
                    onChange={handleInputChange}
                    className={styles.profileInput}
                  />
                </div>
              </div>
              <div className={styles.inputGroup}>
                <p className={styles.profileLabel}>Pays :</p>
                <input
                  type="text"
                  name="country"
                  value={userInfo.address?.country || ''}
                  onChange={handleInputChange}
                  className={styles.profileInput}
                />
              </div>
              <button
                className={styles.buttonStripe}
                type="submit"
                onClick={handleSubmit}
              >
                Valider
              </button>
              {isSaved && <FontAwesomeIcon icon={faCheckCircle} size="xl" className="ml-2 text-green-500" />}
              {isSavedFailed && <FontAwesomeIcon icon={faXmark} size="xl" className="ml-2 text-red-500" />}
              {error && <p className="text-red-500">{error}</p>}
              {successMessage && <p className="text-green-500">{successMessage}</p>}
            </div>

          }

          {!accountCreatePending && !connectedAccountId && (
            <div>
              <button
                className={`${styles.buttonStripe} ${!isSaved ? styles.inactive : ''}`}
                onClick={async () => {
                  if (!userInfo.email || !userInfo.firstName || !userInfo.lastName || !userInfo.birthDate || !userInfo.address) {
                    setError(true);
                    console.error("All user information is required.");
                    return; // Prevent further execution if the user data is incomplete
                  }

                  setAccountCreatePending(true);
                  setError(false);

                  try {
                    const response = await stripeAccount({
                      email: userInfo.email,
                      firstName: userInfo.firstName,
                      lastName: userInfo.lastName,
                      birthDate: userInfo.birthDate,
                      address: userInfo.address
                    });
                    setAccountCreatePending(false);

                    if (response.account && response.url) {
                      setConnectedAccountId(response.account);
                      window.location.href = response.url; // Redirect user to the Stripe onboarding URL
                    } else {
                      setError(true);
                      console.error("Erreur lors de la création du compte Stripe :", response.error);
                    }
                  } catch (error) {
                    setAccountCreatePending(false);
                    setError(true);
                    console.error("Erreur lors de la communication avec l'API Stripe :", error);
                  }
                }}
              >
                Créer mon portefeuille <FontAwesomeIcon icon={faStripe} size='2xl' style={{ color: '#ffffff' }} />
              </button>
            </div>
          )}
          {stripeConnectInstance && (
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectAccountOnboarding
                onExit={() => setOnboardingExited(true)}
              />
            </ConnectComponentsProvider>
          )}
          {error && <p className="error">Something went wrong!</p>}
          {(connectedAccountId || accountCreatePending || onboardingExited) && (
            <div className="dev-callout">
              {connectedAccountId && <p>Your connected account ID is: <code className="bold">{connectedAccountId}</code></p>}
              {accountCreatePending && <p>Creating a connected account...</p>}
              {onboardingExited && <p>The Account Onboarding component has exited</p>}
            </div>
          )}
          {/* <div className="info-callout">
          <p>
            This is a sample app for Connect onboarding using the Account Onboarding embedded component. <a href="https://docs.stripe.com/connect/onboarding/quickstart?connect-onboarding-surface=embedded" target="_blank" rel="noopener noreferrer">View docs</a>
          </p>
        </div> */}
        </div>
      </div>
    </div>
  );
}