// VenueSanityHelpers.js
import sanClient from "../../sanityClient";
import { nanoid } from 'nanoid';

export const fetchSanityVenueRules = async () => {
    const query = `*[_type == "rules"] { _id, "title": value, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityVenueSmoking = async () => {
    const query = `*[_type == "rules" && title match "Fumeur*"] { _id, "title": value, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityVenuePets = async () => {
    const query = `*[_type == "rules" && title match "Animaux*"] { _id, "title": value, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityVenuePMR = async () => {
    const query = `*[_type == "rules" && title match "PMR*"] { _id, "title": value, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityEquipments = async () => {
    const query = `*[_type == "equipment"] { _id, title, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityEntertainment = async () => {
    const query = `*[_type == "equipment" && value match "Loisir*"] { _id, title, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityBedSizes = async () => {
    const query = `*[_type == "bedSize"] { _id, title, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityFlags = async () => {
    const query = `*[_type == "flag"] { _id, title, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityParkingEquip = async () => {
    const query = `*[_type == "equipment" && value match "Parking*"] { _id, title, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityParkingType = async () => {
    const query = `*[_type == "rules" && title match "Parking*"] { _id, title, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityParkingSecurity = async () => {
    const query = `*[_type == "equipment" && (title match "Gardien*" || title match "Caméra*")] { _id, title, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityTowelLinen = async () => {
    const query = `*[_type == "equipment" && value match "Linge*"] { _id, title, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityRentingItems = async () => {
    const query = `*[_type == "equipment" && title match "Lit P*"] { _id, title, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityRoomFlags = async () => {
    const query = `*[_type == "flag"] {_id, title, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityRoomEquipment = async () => {
    const query = `*[_type == "equipment" && value match "room*"] { _id, title, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityBuildingEquipment = async () => {
    const query = `*[_type == "equipment" && value match "buildingEquip*"] { _id, title, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityBuildingAmenities = async () => {
    const query = `*[_type == "equipment" && value match "buildingAmenities*"] { _id, title, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const fetchSanityBedSize = async () => {
    const query = `*[_type == "bedSize"] { _id, title, "iconUrl": icon.asset->url }`;
    return await sanClient.fetch(query);
};

export const uploadImageToSanity = async (file) => {
    try {
        const asset = await sanClient.assets.upload('image', file, {
            filename: file.name,
        });
        return {
            id: asset._id,  // Ensure `_id` is available for `_ref`
            url: asset.url, // URL for preview purposes
        };
    } catch (error) {
        console.error("Image upload to Sanity failed:", error);
        return null;  // Return null if upload fails
    }
};

export const getVenueByUserId = async (userId) => {
    const query = `*[_type == "weddingVenue && createdBy == $userId"]`;
    const Sanitytdata = await sanClient.fetch(query, { userId });
    return Sanitytdata;
}

export const fetchVenueData = async (userId) => {
    const query = `*[_type == "weddingVenue" && createdBy == $userId]
    {
        _id,
    "logo": logo {
        alt,
        asset->{
            _id,
            url
        },
    },
    name,
    address,
    website,
    description,
    latitude,
    longitude,
    Wifi,
    virtualTourUrl,
    maxCapacity,
    gallerie[]{
        asset->{
            _id,
            url
        },
        alt
    },
    "Entertainment": additionalFacilities.entertainment[]-> {
        title,
        _id,
        "iconUrl": icon.asset->url
    },
    "Parking": {
        "TotalSpaces": additionalFacilities.parking.totalSpaces,
        "Equipment": additionalFacilities.parking.Equipement[]-> {
            title,
            _id,
            "iconUrl": icon.asset->url
        },
        "ParkingType": additionalFacilities.parking.parkingType[]->{
            _id,
            title,
            "iconUrl": icon.asset->url
        },
        "Security": additionalFacilities.parking.Security[]-> {
            title,
            _id,
            "iconUrl": icon.asset->url
        }
    },
    "Wifi": additionalFacilities.Wifi,
    "LinenDetails": {
        "Provided": additionalFacilities.linenDetails.provided[]-> {
            title,
            _id,
            "iconUrl": icon.asset->url
        }
    },
    "TowelDetails": {
        "Provided": additionalFacilities.towelDetails.provided[]-> {
            title,
            _id,
            "iconUrl": icon.asset->url
        }
    },
    "towelRate": additionalFacilities.towelDetails.additionalCost,
    "linenRate": additionalFacilities.linenDetails.additionalCost,
    "intReceptionPlaces": additionalFacilities.intReceptionRoom.totalSpaces,
    "intReceptionArea": additionalFacilities.intReceptionRoom.totalSurface,
    "extReceptionPlaces": additionalFacilities.extReceptionRoom.totalSpaces,
    "extReceptionArea": additionalFacilities.extReceptionRoom.totalSurface,
    "petPolicies": petPolicies[]->{
        _id,
        title,
        value,
        "iconUrl": icon.asset->url
    },
    "SmokingPolicy": smokingPolicy[]-> {
        _id,
        title,
        value,
        "iconUrl": icon.asset->url
    },
    "disabledAccessOptions": disabledAccessOptions[]->{
        _id,
        title,
        value,
        "iconUrl": icon.asset->url
    },
    "rentableItems": additionalFacilities.rentableItems[] {
      itemType[]-> {
        _id,
        title,
        "iconUrl": icon.asset->url
      },
      quantity,
      price,
      linenProvided[]-> {
        _id,
        title,
        "iconUrl": icon.asset->url
      }
    },
    "park": park
    }`;
    const Sanitytdata = await sanClient.fetch(query, { userId });
    return Sanitytdata;
}

export const fetchBuildingData = async (venueId) => {
    const query = `*[_type == "building" && weddingVenue._ref == $venueId]
    {        
        _id,
        "weddingVenue": weddingVenue._ref,
        "venueName": weddingVenue->name,
        buildingName,
        totalBeds,
        "equipments": equipments[]->{
                title,
                _id,
                "iconUrl": icon.asset->url
            },
        "amenities": amenities[]->{
                title,
                _id,
                "iconUrl": icon.asset->url
            },
        rooms[]{
            _key,
            name,
            beds,
            "flags": flags[]->{
                title,
                value,
                _id,
                "iconUrl": icon.asset->url
            },
            groomRoom,
            floor,
            "bedSize": bedSize[]{
                "sizeType": sizeType->{
                title,
                _id,
                "iconUrl": icon.asset->url
                },
                quantity
            },
            "equipment": equipment[]->{
                title,
                _id,
                "iconUrl": icon.asset->url
            },
            images[]{
                asset->{
                    _id,
                    url
                },
                alt
            },
        },
        suites[]{
            _key,
            suiteName,
            totalSuiteBeds,
            "suiteFlags": suiteFlags[]->{
                title,
                value,
                _id,
                "iconUrl": icon.asset->url
            },
            roomsInSuite[]{
            _key,
            name,
            beds,
            "flags": flags[]->{
                title,
                value,
                _id,
                "iconUrl": icon.asset->url
            },
            groomRoom,
            floor,
            "bedSize": bedSize[]{
                "sizeType": sizeType->{
                    title,
                    _id,
                    "iconUrl": icon.asset->url
                },
                quantity
            },
            "equipment": equipment[]->{
                title,
                _id,
                "iconUrl": icon.asset->url
            },
            images[]{
                asset->{
                    _id,
                    url
                },
                alt
            },
        }
      },
      images[]{
        asset->{
            _id,
            url
        },
        alt
       },
    }`;
    const Sanitytdata = await sanClient.fetch(query, { venueId });
    return Sanitytdata;
}

export const createOrUpdateVenueInSanity = async (venueData) => {
    try {
        const result = await sanClient.createOrReplace({
            _id: venueData._id || nanoid(),
            _type: 'weddingVenue',
            name: venueData.name,
            logo: { _key: nanoid(), _type: 'image', asset: { _key: nanoid(), _ref: venueData.logo.asset._ref || venueData.logo.asset._id } },
            description: venueData.description,  // Ensure this is an array of blocks, not a plain string
            address: venueData.address,
            latitude: parseFloat(venueData.latitude),
            longitude: parseFloat(venueData.longitude),
            website: venueData.website,

            smokingPolicy: venueData.SmokingPolicy?.map(smoke => ({ _key: nanoid(), _ref: smoke._id })),  // Ensure references to smoking rules
            petPolicies: venueData.petPolicies?.map(pet => ({ _key: nanoid(), _ref: pet._id })),  // References to pet policies
            disabledAccessOptions: venueData.disabledAccessOptions?.map(pmr => ({ _key: nanoid(), _ref: pmr._id })),  // References for disabled access

            buildings: venueData.buildings?.map(building => ({ _key: nanoid(), _ref: building._id })),

            maxCapacity: parseFloat(venueData.maxCapacity),
            park: parseFloat(venueData.park),

            additionalFacilities: {
                intReceptionRoom: {
                    totalSpaces: parseFloat(venueData.intReceptionPlaces) || 0,
                    totalSurface: parseFloat(venueData.intReceptionArea) || 0,
                },
                extReceptionRoom: {
                    totalSpaces: parseFloat(venueData.extReceptionPlaces) || 0,
                    totalSurface: parseFloat(venueData.extReceptionArea) || 0,
                },
                entertainment: venueData.Entertainment?.map(fun => ({ _key: nanoid(), _ref: fun._id })),  // References to entertainment equipment
                parking: {
                    totalSpaces: parseFloat(venueData.Parking.TotalSpaces),
                    parkingType: venueData.Parking?.ParkingType?.map(type => ({ _key: nanoid(), _ref: type.value || type._id })),  // References to parking rules
                    Equipement: venueData.Parking?.Equipment?.map(equip => ({ _key: nanoid(), _ref: equip._id })),  // References to parking equipment
                    Security: venueData.Parking?.Security?.map(sec => ({ _key: nanoid(), _ref: sec._id })),  // References to parking security equipment
                },
                linenDetails: {
                    provided: venueData.linenTowelDetails?.map(ldetail => ({ _key: nanoid(), _ref: ldetail._id })),  // Linen details
                    additionalCost: parseFloat(venueData.linenRate) || 0,
                },
                towelDetails: {
                    provided: venueData.linenTowelDetails?.map(tdetail => ({ _key: nanoid(), _ref: tdetail._id })),  // Towel details
                    additionalCost: parseFloat(venueData.towelRate) || 0,
                },                
                rentableItems: venueData.rentableItems.map(item => ({
                    _key: nanoid(),
                    itemType: Array.isArray(item.itemType)
                        ? item.itemType.map(type => ({
                            _key: nanoid(),
                            _ref: type.value || type._id, // Use `value` if present, otherwise `_id`
                        }))
                        : item.itemType // If it's a single object, process it as a reference
                        ? [{ _key: nanoid(), _ref: item.itemType.value || item.itemType._id }]
                        : [], // Default to an empty array if itemType is neither array nor object
                    price: item.price,
                    quantity: item.quantity,
                    linenProvided: item.linenTowelDetails?.map(ltDetail => ({
                        _key: nanoid(),
                        _ref: ltDetail._id,
                    })) || [],
                })),
                Wifi: venueData.hasWifi || venueData.Wifi,
            },
            gallerie: venueData.gallerie.map(img => ({_key: nanoid(), _type: 'image', asset: { _key: nanoid(), _ref: img.asset._ref || img.asset._id } })),
            virtualTourUrl: venueData.virtualTourUrl,
            createdBy: venueData.createdBy
        });

        console.log('Venue saved:', result);
    } catch (error) {
        console.error('Error saving venue to Sanity:', error);
    }
};


export const createOrUpdateBuildingInSanity = async (buildingData) => {   
    try {
        const result = await sanClient.createOrReplace({
            _id: buildingData._id || nanoid(),
            _type: 'building',
            buildingName: buildingData.buildingName,
            totalBeds: buildingData.totalBeds,
            equipments: buildingData.equipments?.map(equip => ({ _key: nanoid(), _ref: equip._id })),
            amenities: buildingData.amenities?.map(amen => ({ _key: nanoid(), _ref: amen._id })),
            rooms: buildingData.rooms?.map(room => ({
                _key: nanoid(),
                name: room.name,
                beds: parseFloat(room.beds),
                flags: room.flags?.map(flag => ({ _key: nanoid(), _ref: flag._id || flag.value})),
                groomRoom: room.groomRoom,
                isInSuite: room.isInSuite,
                floor: room.floor?.value || '',
                bedSize: room.bedSize?.map(size => ({
                    _key: nanoid(),
                    sizeType: { _ref: size.sizeType?._id || size.value }, // Reference to the `bedSize` type
                    quantity: size.quantity || 1, // Ensure quantity is included and defaults to 1 if not provided
                })),
                equipment: room.equipment?.map(equip => ({ _key: nanoid(), _ref: equip._id || equip.value })),
                images: room.images?.map(img => ({
                    _key: nanoid(),
                    _type: 'image',
                    asset: { _key: nanoid(), _ref: img.asset._id || img.asset._ref},
                })),
            })),
            suites: buildingData.suites?.map(suite => ({
                _key: nanoid(),
                suiteName: suite.suiteName,
                totalSuiteBeds: parseFloat(suite.totalSuiteBeds),
                suiteFlags: suite.suiteFlags?.map(flag => ({ _key: nanoid(), _ref: flag._id || flag.value })),
                roomsInSuite: suite.roomsInSuite?.map(room => ({
                    _key: nanoid(),
                    name: room.name,
                    beds: parseFloat(room.beds),
                    flags: room.flags?.map(flag => ({ _key: nanoid(), _ref: flag._id || flag.value})),
                    groomRoom: room.groomRoom,
                    isInSuite: room.isInSuite,
                    floor: room.floor?.value || '',
                    bedSize: room.bedSize?.map(size => ({
                        _key: nanoid(),
                        sizeType: { _ref: size.sizeType?._id || size.value }, // Use `_ref` for Sanity references
                        quantity: size.quantity || 1, // Ensure quantity is included and defaults to 1 if not provided
                    })),
                    equipment: room.equipment?.map(equip => ({ _key: nanoid(), _ref: equip._id || equip.value  })),
                    images: room.images?.map(img => ({
                        _key: nanoid(),
                        _type: 'image',
                        asset: { _key: nanoid(), _ref: img.asset._ref || img.asset._id },
                    })),
                })),
            })),
            weddingVenue: { _ref: buildingData.weddingVenue },
            images: buildingData.images?.map(img => ({_key: nanoid(), _type: 'image', asset: { _key: nanoid(), _ref: img.asset._id || img.asset._ref } })),
            createdBy: buildingData.createdBy
        });

        console.log('building saved:', result);
    } catch (error) {
        console.error('Error saving venue to Sanity:', error);
    }
};

export const deleteFromSanity = async (id) => {
    try {
        const result = await sanClient.delete(id); // Deletes the document with the given _id
        console.log(`Document with ID ${id} deleted successfully`, result);
        return result;
    } catch (error) {
        console.error(`Error deleting document with ID ${id}:`, error);
        throw error;
    }
};