import React, { useState, useEffect } from 'react';
import { useVenueContext } from '../../contexts/VenuesContext';
//import { getVenueByUserId,  } from '../api/sanityApi'; // Custom API for Sanity createOrUpdateVenue
// import { useHistory } from 'react-router-dom'
import { fetchLatLng } from '../GoogleMapsAPI/FetchLatLng';
import { htmlToBlocks } from '@sanity/block-tools';
import blockContentType from './localSchema';
import Previews from './DnDImgPreview';
import VenueDropdown from './VenueDropdown';
import VenueDropdownMulti from './VenueDropDownMulti';
import SelectVenueModalObject from './SelectVenueModalObject';
import DeleteModal from './DeleteModal';
import VenueQuillForm from '../ReactQuillComponent/VenueFormQuillComp';
import { FaTrash, FaTrashAlt } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from '../../VenueForm.module.css';

const VenueForm = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [hasWifi, setHasWifi] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRentableItem, setSelectedRentableItem] = useState(null);
    const [isNearFooter, setIsNearFooter] = useState(false);
    const { activeVenue, setActiveVenue, addVenue, deleteVenue, userId } = useVenueContext(); // Should I also add activeVenue, setActiveVenue, activeBuilding, setActiveBuilding ?
    const [formValues, setFormValues] = useState(activeVenue || {});
    const [hasChanges, setHasChanges] = useState(false);

    // Set formValues when activeVenue changes or if no activeVenue exists
    useEffect(() => {
        if (activeVenue && Object.keys(activeVenue).length > 0) {
            setFormValues({
                ...activeVenue,
                description: activeVenue.description || '', // Preserve blocks or HTML
            });
            setHasWifi(activeVenue.Wifi || false); // Default to false if undefined
        }
    }, [activeVenue, userId]);    

    useEffect(() => {
        const handleScroll = () => {
            const footer = document.querySelector('footer');
            const saveButton = document.querySelector(`.${styles.buttonSave}`); // Using CSS module class here
            const deleteButton = document.querySelector(`.${styles.buttonDelete}`);

            if (!footer || !saveButton || !deleteButton) {
                // If either footer or saveButton is not found, exit early
                return;
            }

            const rect = footer.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            // Check if the footer is coming into view
            if (rect.top < windowHeight) {
                setIsNearFooter(true);
                // Adjust button position based on footer's distance from bottom
                saveButton.style.bottom = `${rect.height + 50}px`; // 20px padding above the footer
                deleteButton.style.bottom = `${rect.height + 50}px`;
            } else {
                setIsNearFooter(false);
                saveButton.style.bottom = '2svw'; // Default bottom spacing
                deleteButton.style.bottom = '2svw';
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleInputChange = async (event) => {
        const { name, value } = event.target;
        const parsedValue = value === '' ? '' : (isNaN(value) ? value : parseFloat(value));
        const nameParts = name.split('.');
    
        if (name === "address") {
            // Update the address field immediately for responsive typing
            setFormValues((prevData) => ({
                ...prevData,
                address: value,
            }));
    
            // Fetch coordinates asynchronously after setting the address
            try {
                const coordinates = await fetchLatLng(`${formValues.name} ${value}`);
                if (coordinates) {
                    setFormValues((prevData) => ({
                        ...prevData,
                        latitude: coordinates.lat,
                        longitude: coordinates.lng,
                    }));
                }
            } catch (error) {
                console.error('Error fetching coordinates:', error);
            }
        } else {
            setFormValues((prevData) => {
                // Make a copy of prevData for updates
                const updatedData = { ...prevData };
    
                // If name is nested (e.g., "Parking.totalSpaces"), navigate through parts
                let currentLevel = updatedData;
                for (let i = 0; i < nameParts.length - 1; i++) {
                    const part = nameParts[i];
                    if (!currentLevel[part]) {
                        currentLevel[part] = {}; // Ensure intermediate levels exist
                    }
                    currentLevel = currentLevel[part];
                }
    
                // Set the final property to parsedValue
                currentLevel[nameParts[nameParts.length - 1]] = parsedValue;
    
                return updatedData;
            });
        }
        setHasChanges(true)
    };    

    const handleDropdownChange = (name, selectedOption) => {
        setFormValues((prevData) => ({
            ...prevData,
            Parking: {
                ...prevData.Parking,
                [name]: Array.isArray(selectedOption) ? selectedOption : [selectedOption],
            }
        }));
    };

    const handleDescriptionChange = (html) => {
        setFormValues((prev) => ({ ...prev, description: html || ''}));
        setHasChanges(true);
    };

    const handleSaveModal = (updatedItem) => {
        setFormValues((prevValues) => {
            const updatedRentableItems = selectedRentableItem.index !== null
                ? prevValues.rentableItems.map((item, i) => i === selectedRentableItem.index ? updatedItem : item)
                : [...prevValues.rentableItems, updatedItem];

            return {
                ...prevValues,
                rentableItems: updatedRentableItems,
            };
        });

        setModalOpen(false);
    };

    const removeItem = (indexToRemove) => {
        console.log("Attempting to delete rentable item:", indexToRemove);

        setFormValues((prevValues) => {
            const updatedItems = prevValues.rentableItems.filter((_, index) => index !== indexToRemove);

            return {
                ...prevValues,
                rentableItems: updatedItems,
            };
        });
    };

    const handleLogoUpload = (uploadedFiles) => {
        setFormValues((prevData) => ({
            ...prevData,
            logo: {
                asset: { _ref: uploadedFiles[0]?.id, url: uploadedFiles[0]?.url }
            }, // Assuming it's a single image upload for the logo
        }));
    };

    const handleGalleryUpload = (uploadedFiles) => {
        setFormValues((prev) => ({
            ...prev,
            gallerie: [
                ...(prev.gallerie || []),
                ...uploadedFiles.map((file) => ({
                    asset: { _ref: file.id, url: file.url } // Consistent structure
                }))
            ],
        }));
    };

    const handleRemoveLogo = () => {
        setFormValues((prevData) => ({
            ...prevData,
            logo: null, // Set logo to null instead of filtering
        }));
    };

    const handleRemoveImageFromGallery = (imageId) => {
        if (!imageId) {
            console.error("Image ID is undefined in handleRemoveImageFromGallery");
            return;
        };

        setFormValues((prevData) => {
            const updatedImages = prevData.gallerie?.filter(
                (file) => file.asset?._ref !== imageId && file.asset?._id !== imageId
            );
            return {
                ...prevData,
                gallerie: updatedImages || [],
            };
        });
    };

    const htmlToSanityBlocks = (htmlString) => {
        return htmlToBlocks(htmlString, blockContentType);
    };

    const handleSave = async () => {
        
        const blocks = Array.isArray(formValues.description)
            ? formValues.description
            : formValues.description
            ? htmlToSanityBlocks(formValues.description)
            : [];

        const venueData = {
            ...formValues,
            description: blocks,
            Wifi: hasWifi,
            createdBy: userId
        };
        console.log('venueData',venueData)
        try {
            setActiveVenue(venueData);
            // console.log('activeVenue',activeVenue)
            await addVenue(venueData);
            alert('Vos informations ont été correctement sauvegardées !');
        } catch (error) {
            console.error('Error saving venue:', error);
            alert('Une erreur est survenue lors de la sauvegarde');
        };

        setHasChanges(false);
    };

    const handleDeleteVenue = async () => {
        if (activeVenue?._id) {
            await deleteVenue(activeVenue._id); // Call delete function from context
        }
        setShowDeleteModal(false); // Close the modal
    };

    const openModal = (index = null) => {
        // Check if the item exists at the specified index; fallback if not
        const item = index !== null && formValues.rentableItems && formValues.rentableItems[index]
            ? { ...formValues.rentableItems[index], index } // Set index for existing items
            : { itemType: { title: '', iconUrl: '' }, quantity: '', price: '', linenTowelDetails: [], index: null };

        setSelectedRentableItem(item);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <div className={styles.venueFormContainer}>
            <h1 className={styles.venueFormTitle}>
                {formValues?.name && formValues?.name != '' ? `${formValues?.name}` : 'Lieu'}
            </h1><form className={styles.venueForm}>
                <label>Nom du Lieu</label>
                <input type="text" name="name" value={formValues?.name} onChange={handleInputChange} required />

                <label>Logo</label>
                <Previews onUpload={handleLogoUpload} removeImage={handleRemoveLogo}
                    existingImages={formValues.logo && formValues.logo.asset
                        ? [{ id: formValues.logo.asset._id, url: formValues.logo.asset.url }]
                        : []} />

                <label className='mt-2'>Description</label>
                <VenueQuillForm value={formValues?.description} onChange={handleDescriptionChange} />

                <label>Adresse</label>
                <input type="text" name="address" value={formValues?.address} onChange={handleInputChange} required />

                <label>Latitude</label>
                <input type="number" name="latitude" disabled value={formValues?.latitude} onChange={handleInputChange} />

                <label>Longitude</label>
                <input type="number" name="longitude" disabled value={formValues?.longitude} onChange={handleInputChange} />

                <label>Site internet</label>
                <input type="url" name="website" value={formValues?.website} onChange={handleInputChange} />

                <label>Wifi</label>
                <div className={styles.WifiToggleContainer}>
                    <input
                        type="checkbox"
                        id="toggleWifi"
                        checked={hasWifi}
                        onChange={() => setHasWifi(!hasWifi)}
                        className={styles.toggleCheckboxWifi}
                    />
                    <label htmlFor="toggleWifi" className={styles.toggleSwitch}></label>
                </div>

                <VenueDropdownMulti
                    type="smoking"
                    name="smokingPolicies"
                    label="Politique Fumeur"
                    value={formValues?.SmokingPolicy?.map(policy => ({ value: policy._id, label: policy.title, iconUrl: policy.iconUrl }))}
                    onChange={(selectedOptions) => setFormValues({ ...formValues, smokingPolicies: selectedOptions })} />

                <VenueDropdownMulti
                    type="pets"
                    name="petPolicies"
                    label="Politiques pour les Animaux de Compagnie"
                    value={formValues?.petPolicies?.map(policy => ({ value: policy._id, label: policy.title, iconUrl: policy.iconUrl }))}
                    onChange={(selectedOptions) => setFormValues({ ...formValues, petPolicies: selectedOptions })} />

                <VenueDropdownMulti
                    type="PMR"
                    name="pmrAccessibility"
                    label="Accessibilité handicapé"
                    value={Array.isArray(formValues?.disabledAccessOptions)
                        ? formValues?.disabledAccessOptions.map(option => ({ value: option._id, label: option.title, iconUrl: option.iconUrl }))
                        : []}
                    onChange={(selectedOptions) => {
                        setFormValues({ ...formValues, disabledAccessOptions: selectedOptions });
                    }} />

                <label>Capacité maximale de couchage</label>
                <input type="number" name="maxCapacity" value={formValues?.maxCapacity} onChange={handleInputChange} placeholder='La capacité maximale du lieu de mariage en termes de nombre de personnes.' />

                <label>Parc (en hectares)</label>
                <input type="number" name="park" value={formValues?.park} onChange={handleInputChange} placeholder='La surface totale du parc du domaine en hectares. Utilisez des valeurs décimales pour les fractions d’hectare.' />

                <label>Installations supplémentaires</label>
                <div className={styles.subCategory}>
                    <label>Salles de Réception en Intérieur</label>
                    <div className={styles.subCategory}>
                        <label>Nombre total de places</label>
                        <input type="number" name="intReceptionPlaces" label='Nombre total de places' value={formValues?.intReceptionPlaces || ''} onChange={handleInputChange} placeholder='Le nombre total de places disponibles dans les salles de réception.' />
                        <label>Surface totale (en m²)</label>
                        <input type="number" name="intReceptionArea" label='Nombre total de places' value={formValues?.intReceptionArea || ''} onChange={handleInputChange} placeholder='La surface totale des salles de réception en mètres carrés (m²).' />
                    </div>

                    <label>Salles de Réception en Extérieur (Ex: Orangerie)</label>
                    <div className={styles.subCategory}>
                        <label>Nombre total de places</label>
                        <input type="number" name="extReceptionPlaces" label='Nombre total de places' value={formValues?.extReceptionPlaces || ''} onChange={handleInputChange} placeholder='Le nombre total de places disponibles dans les salles de réception.' />
                        <label>Surface totale (en m²)</label>
                        <input type="number" name="extReceptionArea" label='Nombre total de places' value={formValues?.extReceptionArea || ''} onChange={handleInputChange} placeholder='La surface totale des salles de réception en mètres carrés (m²).' />
                    </div>
                    <VenueDropdownMulti type="entertainment" name="Entertainment" label="Loisirs"
                        value={Array.isArray(formValues?.Entertainment)
                            ? formValues?.Entertainment.map(option => ({ value: option._id, label: option.title, iconUrl: option.iconUrl }))
                            : []}
                        onChange={(selectedOptions) => {
                            setFormValues({ ...formValues, Entertainment: selectedOptions });
                        }}
                    />

                    <label>Parking</label>
                    <div className={styles.subCategory}>
                        <VenueDropdown
                            type="parkingType"
                            name="ParkingType"
                            label="Type de parking"
                            value={Array.isArray(formValues?.Parking?.ParkingType)
                                ? formValues?.Parking?.ParkingType?.map(type => ({
                                    label: type.title || type.label,
                                    iconUrl: type.iconUrl,
                                    value: type.value || type.label || type._id,
                                }))
                                : []}
                            onChange={(selectedOption) => handleDropdownChange("ParkingType", selectedOption)} />

                        <label>Nombre total de places</label>
                        <input type="number" name="Parking.TotalSpaces" label="Nombre total de places" value={formValues?.Parking?.TotalSpaces || ''} onChange={handleInputChange} placeholder="Nombre total de places."
                        />
                        <VenueDropdownMulti type="parkingEquip" name="parkingEquip" label="Equipements du parking"
                            value={Array.isArray(formValues?.Parking?.Equipment)
                                ? formValues?.Parking?.Equipment?.map(equipment => ({
                                    label: equipment.title,
                                    iconUrl: equipment.iconUrl,
                                    value: equipment.value || equipment.label || equipment._id
                                }))
                                : []}
                            onChange={(selectedOptions) => {
                                setFormValues((prevData) => ({
                                    ...prevData,
                                    Parking: {
                                        ...prevData.Parking,
                                        Equipment: selectedOptions,
                                    }
                                }));
                                // setFormValues({ ...formValues, parkingEquip: selectedOptions });
                            }} />

                        <VenueDropdownMulti
                            type="parkingSecurity"
                            name="parkingSecurity"
                            label="Surveillance"
                            value={Array.isArray(formValues?.Parking?.Security)
                                ? formValues?.Parking?.Security?.map(security => ({
                                    label: security.title,
                                    iconUrl: security.iconUrl,
                                    value: security.value || security.label || security._id
                                }))
                                : []}
                            onChange={(selectedOptions) => {
                                setFormValues((prevData) => ({
                                    ...prevData,
                                    Parking: {
                                        ...prevData.Parking,
                                        Security: selectedOptions,
                                    }
                                }));
                                // setFormValues({ ...formValues, parkingSecurity: selectedOptions });
                            }} />
                    </div>

                    <label>Détails sur le linge de lit et de toilette</label>
                    <div className={styles.subCategory}>
                        <VenueDropdownMulti
                            type="linenTowelDetails"
                            name="linenTowelDetails"
                            label="Indiquez quels linges sont fournis"
                            value={formValues?.LinenDetails?.Provided?.map(detail => ({ value: detail._id, label: detail.title, iconUrl: detail.iconUrl }))}
                            onChange={(selectedOptions) => setFormValues({ ...formValues, linenTowelDetails: selectedOptions })} />

                        <label>Coût supplémentaire du linge de lit</label>
                        <input type="number" name="linenRate" label='Coût supplémentaire du linge de lit' value={formValues?.linenRate} onChange={handleInputChange} placeholder="Mettez 0 si c'est inclus." />

                        <label>Coût supplémentaire du linge de toilette</label>
                        <input type="number" name="towelRate" label='Coût supplémentaire du linge de toilette' value={formValues?.towelRate} onChange={handleInputChange} placeholder="Mettez 0 si c'est inclus." />
                    </div>

                    <label>Items à louer</label>
                    <div className={styles.subCategory}>
                        {/* Display selected items */}
                        <div className={styles.subCategory}>
                            {/* Display selected items */}
                            <div className={styles.selectedItemsContainer}>
                                {formValues.rentableItems && formValues.rentableItems.length > 0 ? (
                                    formValues.rentableItems.map((item, index) => {

                                        const itemType = Array.isArray(item.itemType) && item.itemType.length > 0
                                            ? item.itemType[0] // Use the first element if `itemType` is an array
                                            : item.itemType;

                                        return (
                                            <div key={index} className={styles.selectedItem}>
                                                <div
                                                    className={styles.itemDetails}
                                                    onClick={() => openModal(index)}
                                                >
                                                    {/* Display main itemType details */}
                                                    <img
                                                        src={itemType.iconUrl}
                                                        alt={itemType.title || itemType.label} // Use `title` or `label` as per structure
                                                        className={styles.selectedIcon} />
                                                    <span className={styles.selectedSpan}>{itemType.title || itemType.label}</span>
                                                    <span className={styles.selectedSpan}>| Quantité: {item.quantity}</span>
                                                    <span className={styles.selectedSpan}>
                                                        | Prix: {item.price && item.price > 0 ? `${item.price} €` : 'Offert'} |
                                                    </span>

                                                    {/* Display linenTowelDetails if they exist */}
                                                    {Array.isArray(item.linenTowelDetails) && item.linenTowelDetails.length > 0 &&
                                                        item.linenTowelDetails.map((detail, i) => (
                                                            <React.Fragment key={i}>
                                                                <img
                                                                    src={detail.iconUrl}
                                                                    alt={detail.label}
                                                                    className={styles.selectedIcon} />
                                                                <span>{detail.label || detail.title}</span>
                                                            </React.Fragment>
                                                        ))}
                                                </div>
                                                <div className={styles.iconContainer}>
                                                    <button
                                                        className={styles.deleteButton}
                                                        onClick={(e) => {
                                                            e.preventDefault(); // Prevents form submission
                                                            e.stopPropagation();
                                                            removeItem(index);
                                                        }}
                                                    >
                                                        <FaTrash />
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <p className={styles.noItemsText}>Aucun item sélectionné</p>
                                )}
                            </div>
                            <button type="button" className={styles.openModalButton} onClick={openModal}>
                                <FontAwesomeIcon icon={faPlus} className={styles.chevronIcon} /> Ajoutez des items
                            </button>
                        </div>

                        {isModalOpen && (
                            <SelectVenueModalObject
                                label="Sélectionnez un ou des items louables"
                                rentableItemData={selectedRentableItem} // Pass the item to edit or empty fields for a new item
                                handleInputChange={handleInputChange}
                                onSave={handleSaveModal}
                                onCancel={closeModal} />
                        )}
                    </div>
                </div>

                <label>Visite virtuelle (URL)</label>
                <input type="url" name="virtualTourUrl" value={formValues?.virtualTourUrl} onChange={handleInputChange} />

                <label>Galerie</label>
                <Previews
                    onUpload={handleGalleryUpload}
                    removeImage={handleRemoveImageFromGallery}
                    existingImages={formValues?.gallerie?.map((img) => ({
                        id: img.asset?._id || img.asset?._ref,
                        url: img.asset?.url
                    })) || []} />

                <button
                    className={styles.buttonDelete}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowDeleteModal(true);                      
                    }}
                >
                    <FaTrashAlt />
                </button>
                <DeleteModal
                    isOpen={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={handleDeleteVenue}
                    entityName="le lieu de mariage"
                />

                <button 
                    type="button" 
                    onClick={handleSave} 
                    className={`${styles.buttonSave} ${hasChanges ? styles.pulse : ''}`}
                >
                    Sauvegarder
                </button>
            </form>
        </div>
    );
};

export default VenueForm;
//className='sub-category'