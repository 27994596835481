import React, { useState, useEffect } from 'react';
import Modal from 'react-modal'; // You can use other modal libraries too
import VenueDropdownMulti from './VenueDropDownMulti';
import styles from '../../SelectModal.module.css'; // Custom styles
import '../../VenueDropdown.css';

const SelectBedSizeModal = ({ bedSizeData, onSave, onCancel }) => {
    const [bedSize, setBedSize] = useState({
        index: bedSizeData?.index !== undefined ? bedSizeData.index : null,
        sizeType: bedSizeData?.sizeType || [],
        quantity: bedSizeData?.quantity || ''
    });    

    useEffect(() => {
        console.log('opening selectbedsizemodal with', bedSizeData)
        console.log('opening selectbedsizemodal with bedSize', bedSizeData)
    }, [bedSizeData, bedSize]);

    const [modalStyle, setModalStyle] = useState({
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9990,
            minWidth: '25%',
            maxWidth: '45%',
            // minHeight: '70%',
            overflow: 'auto',
            borderRadius: '10px',
            color: '#000000',
            paddingTop: '20px',
            paddingRight: '20px',
            paddingLeft: '20px',
            paddingBottom: '20px',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9998,
        },
    });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '95%',
                    },
                }));
            } else if (window.innerWidth <= 1200) {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '75%',
                        minWidth: '70%',
                    },
                }));
            } else {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '45%',
                    },
                }));
            }
        };

        handleResize(); // Check the initial window size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSave = () => {
        console.log("bedSize in handleSave modal", bedSize);
    
        onSave({
            ...bedSize,
            index: bedSize.index !== undefined && bedSize.index !== '' ? parseInt(bedSize.index, 10) : null, // Ensure index is passed
            quantity: parseInt(bedSize.quantity, 10), // Ensure quantity is stored as a number
            sizeType: bedSize.sizeType, // Ensure sizeType is updated when changed
        });
    
        onCancel();
    };
    
    return (
        <Modal isOpen={true} onRequestClose={onCancel} style={modalStyle}>
            <h2 className={styles.modalTitle}>Sélectionnez un type de lit</h2>

            <VenueDropdownMulti
                type="bedSize"
                name="bedSize"
                label="Taille du ou des lits"
                value={bedSize?.sizeType && bedSize.sizeType._id ? [{ 
                    value: bedSize.sizeType._id, 
                    label: bedSize.sizeType.title, 
                    iconUrl: bedSize.sizeType.iconUrl 
                }] : []}
                onChange={(selectedOption) => 
                    setBedSize({ ...bedSize, sizeType: selectedOption[0] || null }) // Ensure single selection
                }
            />

            <label>Quantité</label>
            <input
                type="number"
                name="bedSizeQuantity"
                value={bedSize.quantity}
                onChange={(e) => setBedSize({ ...bedSize, quantity: e.target.value })}
                placeholder='La nombre de ce type de lit dans cette chambre.'
                className={styles.customModalInput}
            />

            <div className={styles.modalButtonsRoom}>
                <button className={styles.cancelButton} onClick={onCancel}>
                    Annuler
                </button>
                <button className={styles.saveButton} onClick={handleSave}>
                    Confirmer
                </button>
            </div>
        </Modal>
    );
};

export default SelectBedSizeModal;