import React, { useState, useEffect } from 'react';
import Modal from 'react-modal'; // You can use other modal libraries too
import VenueDropdownMulti from './VenueDropDownMulti';
import VenueDropdown from './VenueDropdown';
import SelectBedSizeModal from './SelectBedSizeModal';
import Previews from './DnDImgPreview';
import { FaTrash } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from '../../SelectModal.module.css'; // Custom styles
import '../../VenueDropdown.css';
import stylesVenue from '../../VenueForm.module.css';

const SelectRoomModal = ({ roomData, onSave, onCancel }) => {
    const [isGroomRoom, setIsGroomRoom] = useState(false);
    const [isInSuite, setIsInSuite] = useState(false);
    const [selectedBedSize, setSelectedBedSize] = useState(null);
    const [isBedSizeModalOpen, setBedSizeModalOpen] = useState(false);
    const [room, setRoom] = useState({
        name: roomData?.name || '',
        beds: roomData?.beds || '',
        flags: roomData?.flags || [],
        groomRoom: roomData?.groomRoom || false,
        isInSuite: roomData?.isInSuite || false,
        floor: roomData?.floor || '',
        bedSize: roomData?.bedSize || [],
        equipment: roomData?.equipment || [],
        images: roomData?.images || [],
    });

    useEffect(() => {
        setRoom({
            // name: roomData.name || '',
            // beds: roomData.beds || '',
            // flags: roomData.flags || [],
            // groomRoom: roomData.groomRoom || false,
            // isInSuite: roomData.isInSuite || false,
            // floor: roomData.floor || '',
            ...roomData,
            bedSize: roomData?.bedSize || [],
            // equipment: roomData.equipment || [],
            // images: roomData.images || [],
        });

        if (roomData) {
            setIsGroomRoom(roomData.groomRoom || false);
            setIsInSuite(roomData.isInSuite || false);
        }
    }, [roomData]);

    const [modalStyle, setModalStyle] = useState({
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9990,
            minWidth: '25%',
            maxWidth: '45%',
            minHeight: '70%',
            overflow: 'auto',
            borderRadius: '10px',
            color: '#000000',
            paddingTop: '20px',
            paddingRight: '20px',
            paddingLeft: '20px',
            paddingBottom: '20px',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9998,
        },
    });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '95%',
                    },
                }));
            } else if (window.innerWidth <= 1200) {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '75%',
                        minWidth: '70%',
                    },
                }));
            } else {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '45%',
                    },
                }));
            }
        };

        handleResize(); // Check the initial window size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // const openBedSizeModal = (bedSizeData = null) => {
    //     console.log("bedSizeData in openBedSizeModal", openBedSizeModal)
    //     setSelectedBedSize(bedSizeData);
    //     setBedSizeModalOpen(true);
    // };

    const openBedSizeModal = (index = null) => {
        console.log('opening modal with bedSize index ', index);
        
        if (index !== null && index !== undefined) {
            const selectedBedSizeData = { ...room.bedSize[index], index };
            console.log('selectedBedSizeData in openBedSizeModal', selectedBedSizeData);
            setSelectedBedSize(selectedBedSizeData);
        } else {
            const newBedSizeData = {
                sizeType: null,
                quantity: '',
                index: room.bedSize.length, // Ensure new entries get an index
            };
            console.log('Creating new bedSizeData:', newBedSizeData);
            setSelectedBedSize(newBedSizeData);
        }
    
        setBedSizeModalOpen(true);
    };
    


    const closeBedSizeModal = () => {
        setSelectedBedSize(null);
        setBedSizeModalOpen(false);        
    };

    const handleSaveBedSize = (updatedBedSize) => {
        setRoom(prev => {
            const updatedBedSizes = [...prev.bedSize];

            if (updatedBedSize.index !== undefined && updatedBedSize.index !== null && updatedBedSize.index < updatedBedSizes.length) {
                // Update existing entry and ensure sizeType is properly formatted
                updatedBedSizes[updatedBedSize.index] = {
                    ...updatedBedSize,
                    sizeType: updatedBedSize.sizeType?.sizeType || updatedBedSize.sizeType, // Flatten structure
                };
            } else {
                // Add new entry and ensure correct structure
                updatedBedSize.index = updatedBedSizes.length;
                updatedBedSizes.push({
                    ...updatedBedSize,
                    sizeType: updatedBedSize.sizeType?.sizeType || updatedBedSize.sizeType, // Flatten structure
                });
            }

            console.log("Updated bed sizes after handleSaveBedSize:", updatedBedSizes);

            return {
                ...prev,
                bedSize: updatedBedSizes,
            };
        });
    
        closeBedSizeModal();
    };

    const removeBedSize = (indexToRemove) => {
        setRoom((prev) => ({
            ...prev,
            bedSize: prev.bedSize.filter((_, index) => index !== indexToRemove),
        }));
    };

    const handleSave = () => {
        const flattenedRoom = {
            name: room.name,
            beds: room.beds,
            floor: room.floor,
            groomRoom: isGroomRoom,
            isInSuite: isInSuite,
            bedSize: room.bedSize.map(item => ({
                sizeType: { 
                    _id: item.sizeType?._id || item.value, 
                    title: item.sizeType?.title || item.label, 
                    iconUrl: item.sizeType?.iconUrl || item.iconUrl 
                },
                quantity: item.quantity ? parseInt(item.quantity, 10) : 1, // Ensure quantity is stored as an integer
            })),
            equipment: room.equipment.map(item => ({
                value: item._id || item.value,
                label: item.title || item.label,
                iconUrl: item.iconUrl,
            })),
            flags: room.flags.map(item => ({
                value: item._id || item.value,
                label: item.title || item.label,
                iconUrl: item.iconUrl,
            })),
            images: room.images.map(file => ({
                asset: {
                    _ref: file.asset?._ref || file.asset?._id || file._id,
                    url: file.asset?.url || file.url,
                },
            }))
        };
        console.log('flattenedRoom', flattenedRoom)
        onSave(flattenedRoom);
    };

    const handleGalleryUpload = (uploadedFiles) => {
        setRoom((prev) => ({
            ...prev,
            images: [
                ...(prev.images || []),
                ...uploadedFiles.map((file) => ({
                    asset: { _ref: file.id, url: file.url } // Consistent structure
                }))
            ],
        }));
    };

    const handleRemoveImageFromGallery = (imageId) => {
        if (!imageId) {
            console.error("Image ID is undefined in handleRemoveImageFromGallery");
            return;
        }

        setRoom((prevData) => {
            return {
                ...prevData,
                images: prevData.images
                    ? prevData.images.filter((file) => file?.asset?._id !== imageId)
                    : []
            };
        });
    };

    useEffect(() => {
        console.log("selectedBedSizeData updated:", selectedBedSize);
    }, [selectedBedSize]);

    useEffect(() => {
        console.log("Updated room state:", room);
    }, [room]);

    return (
        <Modal isOpen={true} onRequestClose={onCancel} style={modalStyle}>
            <h2 className={styles.modalTitle}>Configurez la chambre {room.name}</h2>

            <label>Nom de la chambre</label>
            <input
                type="text"
                name="roomName"
                value={room.name}
                onChange={(e) => setRoom({ ...room, name: e.target.value })}
                required
                className={styles.customModalInput}
            />

            <label>Nombre de couchage</label>
            <input
                type="number"
                name="roomBeds"
                value={room.beds}
                onChange={(e) => setRoom({ ...room, beds: e.target.value })}
                placeholder='La nombre de couchages dans cette chambre.'
                className={styles.customModalInput}
            />

            <label>Chambre des mariés ?</label>
            <div className={styles.pricingToggleContainer}>
                <input
                    type="checkbox"
                    id="toggleGroom"
                    checked={isGroomRoom}
                    onChange={() => setIsGroomRoom(!isGroomRoom)}
                    className={styles.toggleCheckboxGroom}
                />
                <label htmlFor="toggleGroom" className={styles.toggleSwitch}></label>
            </div>

            <label>Chambre dans une suite ?</label>
            <div className={styles.pricingToggleContainer}>
                <input
                    type="checkbox"
                    id="toggleSuite"
                    checked={isInSuite}
                    onChange={() => setIsInSuite(!isInSuite)}
                    className={styles.toggleCheckbox}
                />
                <label htmlFor="toggleSuite" className={styles.toggleSwitch}></label>
            </div>

            <div className={stylesVenue.venueBuildingRoom}>
                {/* Display selected items */}
                <div className={stylesVenue.selectedItemsContainer}>
                {room.bedSize?.length > 0 ? (
                    room.bedSize.map((bed, index) => (
                        <div key={index} className={stylesVenue.selectedItem} onClick={() => openBedSizeModal(index)} >
                            <div className={stylesVenue.itemDetails}>
                            <span className={stylesVenue.selectedSpan}> Lit {bed.sizeType?.title || bed.sizeType?.sizeType?.title || 'N/A'}</span>
                            <span className={stylesVenue.selectedSpan}>| Quantité: {bed.quantity}</span>
                                <div className={stylesVenue.iconContainer}>
                                    <div className={stylesVenue.verticalLine}></div>
                                    <button className={stylesVenue.deleteButton} onClick={(e) => {
                                        e.stopPropagation();
                                        removeBedSize(index);
                                    }}>
                                        <FaTrash />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                    ) : (
                        <p className={stylesVenue.noItemsText}>Aucun lit encore ajouté</p>
                    )}
                </div>
                <button type="button" className={stylesVenue.openModalButton} onClick={(e) => { e.preventDefault(); openBedSizeModal(); }}>
                    <FontAwesomeIcon icon={faPlus} className={stylesVenue.chevronIcon} /> Ajoutez un lit
                </button>

                {isBedSizeModalOpen && 
                    <SelectBedSizeModal
                        bedSizeData={selectedBedSize} // Pass buildingData to the modal
                        //handleInputChange={handleInputChange} // Pass the change handler to update state
                        onSave={handleSaveBedSize}
                        onCancel={closeBedSizeModal}
                    />
                }
            </div>

            <VenueDropdownMulti
                type="roomEquipment"
                name="roomEquipment"
                label="Indiquez les équipements de la chambre"
                value={room.equipment}
                onChange={(selectedOptions) => {
                    setRoom({ ...room, equipment: selectedOptions });
                }}
            />

            <VenueDropdownMulti
                type="roomFlag"
                name="roomFlag"
                label="Indiquez pour quel(s) groupe(s) cette chambre est adaptée"
                value={room.flags}
                onChange={(selectedOptions) => {
                    setRoom({ ...room, flags: selectedOptions });
                }}
            />

            <VenueDropdown
                type="floor"
                label="Étage"
                value={room.floor}
                onChange={(selectedOption) => {
                    setRoom({ ...room, floor: selectedOption });
                }}
            />

            <label>
                Galerie
            </label>
            <Previews
                onUpload={handleGalleryUpload}
                removeImage={handleRemoveImageFromGallery}
                existingImages={room?.images?.map((img) => ({
                    id: img.asset?._id || img.asset?._ref,
                    url: img.asset?.url
                })) || []}
            />

            <div className={styles.modalButtonsRoom}>
                <button className={styles.cancelButton} onClick={onCancel}>
                    Annuler
                </button>
                <button className={styles.saveButton} onClick={handleSave}>
                    Confirmer
                </button>
            </div>
        </Modal>
    );
};

export default SelectRoomModal;